import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Home from "./components/Home";
import DepositChain1 from "./components/DepositChain1";
import DepositAccountChain1 from "./components/account/DepositAccountChain1";
import WithdrawChain1 from "./components/WithdrawChain1";
import WithdrawAccountChain1 from "./components/account/WithdrawAccountChain1";
import DepositChain2 from "./components/DepositChain2";
import WithdrawChain2 from "./components/WithdrawChain2";
import DepositAccountChain2 from "./components/account/DepositAccountChain2";
import WithdrawAccountChain2 from "./components/account/WithdrawAccountChain2";
import DepositChain3 from "./components/DepositChain3";
import WithdrawChain3 from "./components/WithdrawChain3";
import DepositAccountChain3 from "./components/account/DepositAccountChain3";
import WithdrawAccountChain3 from "./components/account/WithdrawAccountChain3";
import { delay, motion } from "framer-motion";
import DepositChain4 from './components/DepositChain4';
import WithdrawChain4 from './components/WithdrawChain4';
import DepositAccountChain4 from './components/account/DepositAccountChain4';
import WithdrawAccountChain4 from './components/account/WithdrawAccountChain4';
import DepositChain5 from './components/DepositChain5';
import WithdrawChain5 from './components/WithdrawChain5';
import DepositAccountChain5 from './components/account/DepositAccountChain5';
import WithdrawAccountChain5 from './components/account/WithdrawAccountChain5';

function App() {
  const pageTransition = {
    hidden: { opacity: 0, rotateX: 90 },
    visible: {
      opacity: 1,
      rotateX: 0,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
        duration: 0.5,
        // delay: 0.5
      }
    }
  };

  return (
    <>
      <BrowserRouter>
        <Header />
        <div className="main_wrap">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/deposit/pom" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositChain1 />
              </motion.div>
            } />
            <Route path="/withdraw/pom" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawChain1 />
              </motion.div>
            } />
            <Route path="/account/deposit/pom" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositAccountChain1 />
              </motion.div>
            } />
            <Route path="/account/withdraw/pom" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawAccountChain1 />
              </motion.div>
            } />

            <Route path="/deposit/sen" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositChain2 />
              </motion.div>
            } />
            <Route path="/withdraw/sen" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawChain2 />
              </motion.div>
            } />
            <Route path="/account/deposit/sen" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositAccountChain2 />
              </motion.div>
            } />
            <Route path="/account/withdraw/sen" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawAccountChain2 />
              </motion.div>
            } />

            <Route path="/deposit/meta" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositChain3 />
              </motion.div>
            } />
            <Route path="/withdraw/meta" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawChain3 />
              </motion.div>
            } />
            <Route path="/account/deposit/meta" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositAccountChain3 />
              </motion.div>
            } />
            <Route path="/account/withdraw/meta" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawAccountChain3 />
              </motion.div>
            } />

            <Route path="/deposit/lava" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositChain4 />
              </motion.div>
            } />
            <Route path="/withdraw/lava" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawChain4 />
              </motion.div>
            } />
            <Route path="/account/deposit/lava" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositAccountChain4 />
              </motion.div>
            } />
            <Route path="/account/withdraw/lava" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawAccountChain4 />
              </motion.div>
            } />

            <Route path="/deposit/dustboy" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositChain5 />
              </motion.div>
            } />
            <Route path="/withdraw/dustboy" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawChain5 />
              </motion.div>
            } />
            <Route path="/account/deposit/dustboy" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <DepositAccountChain5 />
              </motion.div>
            } />
            <Route path="/account/withdraw/dustboy" element={
              <motion.div initial="hidden" animate="visible" variants={pageTransition}>
                <WithdrawAccountChain5 />
              </motion.div>
            } />

          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
