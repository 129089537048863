import React from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const ViewChain5 = () => {
  const location = useLocation();

  return (
    <Nav className="smaller-tabs justify-content-center">
      <Nav.Item>
        <Nav.Link as={Link} to="/account/deposit/dustboy">
          View deposits ⬇︎
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/account/withdraw/dustboy">
          View Withdraws ⬆︎
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default ViewChain5;