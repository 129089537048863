import React, { useEffect, useState } from "react";
import "../assets/style/deposit.scss";
import "../assets/style/withdraw.scss";
import { Form, Image, Spinner } from "react-bootstrap";
import { Usdt, Usdc, Dai, Ethereum } from "react-web3-icons";
import { MdOutlineSecurity } from "react-icons/md";
import jbc from "../assets/images/jbc-logo.png";
import Web3 from "web3";
import dustboy from "../assets/images/dustboy.png";
import {
  useAccount,
  useConnect,
  useNetwork,
  useSwitchNetwork,
  useBalance,
} from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { IoMdWallet } from "react-icons/io";
import { HiSwitchHorizontal } from "react-icons/hi";
import metamask from "../assets/images/metamask.svg";
import ViewChain3 from "./ViewChain3";
import { FaExternalLinkAlt } from "react-icons/fa";
import TabMenuChain5 from "./TabMenuChain5";
import ViewChain5 from "./ViewChain5";
const optimismSDK = require("@eth-optimism/sdk");
const ethers = require("ethers");


const WithdrawChain5 = () => {
  const [ethValue, setEthValue] = useState("");
  const [sendToken, setSendToken] = useState("USDT");
  const [errorInput, setErrorInput] = useState("");
  const [checkMetaMask, setCheckMetaMask] = useState("");
  const [loader, setLoader] = useState(false);
  const { address, isConnected } = useAccount();
  const { chain, chains } = useNetwork();
  const { connect } = useConnect({
    connector: new InjectedConnector({ chains }),
    onError(error) {
      console.log("Error", error);
    },
    onMutate(args) {
      console.log("Mutate", args);
      if (args.connector.ready === true) {
        setCheckMetaMask(false);
      } else {
        setCheckMetaMask(true);
      }
    },
    onSettled(data, error) {
      console.log("Settled", { data, error });
    },
    onSuccess(data) {
      console.log("Success", data);
    },
  });
  const [metaMastError, setMetaMaskError] = useState("");
  const { error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork({
    // throwForSwitchChainNotSupported: true,
    chainId: process.env.REACT_APP___CHAIN5___L2_CHAIN_ID,
    onError(error) {
      console.log("Error", error);
    },
    onMutate(args) {
      console.log("Mutate", args);
    },
    onSettled(data, error) {
      console.log("Settled", { data, error });
      try {
        window.ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: process.env.REACT_APP___CHAIN5___L2_CHAIN_ID_WITH_HEX,
                rpcUrls: [process.env.REACT_APP___CHAIN5___L2_RPC_URL],
                chainName: process.env.REACT_APP___CHAIN5___L2_NETWORK_NAME,
                nativeCurrency: {
                  name: "ETHEREUM",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: [process.env.REACT_APP___CHAIN5___L2_EXPLORER_URL],
              },
            ],
          })
          .then((data) => {
            setMetaMaskError("");
          })
          .catch((err) => {
            if (err.code === -32002) {
              setMetaMaskError("Request stuck in pending state");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    onSuccess(data) {
      console.log("Success", data);
    },
  });
  //========================================================== BALANCES =======================================================================

  const { data } = useBalance({
    address: address,
    chainId: Number(process.env.REACT_APP___CHAIN5___L2_CHAIN_ID),
    watch: true,
  });
  const dataUSDT = useBalance({
    address: address,
    chainId: Number(process.env.REACT_APP___CHAIN5___L2_CHAIN_ID),
    token: process.env.REACT_APP___CHAIN5___L2_USDT,
    watch: true,
  });


  ////========================================================== WITHDRAW =======================================================================

  const handleWithdraw = async () => {
    try {
      if (!ethValue) {
        setErrorInput("Please enter the amount");
      } else {
        if (!parseFloat(ethValue) > 0) {
          setErrorInput("Invalid Amount Entered!");
        } else {
          setErrorInput("");
          const l1Url = process.env.REACT_APP_L1_RPC_URL;
          const l1Provider = new ethers.providers.JsonRpcProvider(l1Url, "any");
          const l2Provider = new ethers.providers.Web3Provider(window.ethereum);
          const l1Signer = l1Provider.getSigner(address);
          const l2Signer = l2Provider.getSigner(address);
          const zeroAddr = "0x".padEnd(42, "0");
          const l1Contracts = {
            StateCommitmentChain: zeroAddr,
            CanonicalTransactionChain: zeroAddr,
            BondManager: zeroAddr,
            AddressManager: process.env.REACT_APP___CHAIN5___LIB_ADDRESSMANAGER,
            L1CrossDomainMessenger:
              process.env.REACT_APP___CHAIN5___PROXY_OVM_L1CROSSDOMAINMESSENGER,
            L1StandardBridge: process.env.REACT_APP___CHAIN5___PROXY_OVM_L1STANDARDBRIDGE,
            OptimismPortal: process.env.REACT_APP___CHAIN5___OPTIMISM_PORTAL_PROXY,
            L2OutputOracle: process.env.REACT_APP___CHAIN5___L2_OUTPUTORACLE_PROXY,
          };
          const bridges = {
            Standard: {
              l1Bridge: l1Contracts.L1StandardBridge,
              l2Bridge: "0x4200000000000000000000000000000000000010",
              Adapter: optimismSDK.StandardBridgeAdapter,
            },
            ETH: {
              l1Bridge: l1Contracts.L1StandardBridge,
              l2Bridge: "0x4200000000000000000000000000000000000010",
              Adapter: optimismSDK.ETHBridgeAdapter,
            },
          };
          const crossChainMessenger = new optimismSDK.CrossChainMessenger({
            contracts: {
              l1: l1Contracts,
            },
            bridges: bridges,
            l1ChainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
            l2ChainId: Number(process.env.REACT_APP___CHAIN5___L2_CHAIN_ID),
            l1SignerOrProvider: l1Signer,
            l2SignerOrProvider: l2Signer,
            bedrock: true,
          });
          //-------------------------------------------------------- SEND TOKEN VALUE -----------------------------------------------------------------

          try {
            // if (sendToken == "ETH") {
            //   const weiValue = parseInt(
            //     ethers.utils.parseEther(ethValue)._hex,
            //     16,
            //   );
            //   setLoader(true);
            //   const response = await crossChainMessenger.withdrawETH(
            //     weiValue.toString(),
            //   );
            //   const logs = await response.wait();
            //   if (logs) {
            //     setLoader(false);
            //     setEthValue("");
            //   }
            // }

            // if (sendToken == "USDT") {
            //   var assetValue = Web3.utils.toWei(ethValue, "ether");
            //   setLoader(true);
            //   var depositTxn2 = await crossChainMessenger.withdrawERC20(
            //     process.env.REACT_APP_L1_USDT,
            //     process.env.REACT_APP_L2_USDT,
            //     assetValue,
            //   );
            //   var receiptUSDT = await depositTxn2.wait();
            //   if (receiptUSDT) {
            //     setLoader(false);
            //     setEthValue("");
            //   }
            // }

            // if (sendToken == "ETH") {
            //   const weiValue = ethers.utils.parseEther(ethValue);

            //   const l2Provider = new ethers.providers.Web3Provider(window.ethereum);
            //   const l2Signer = l2Provider.getSigner();

            //   const l2Contract = new ethers.Contract(
            //     "0x4200000000000000000000000000000000000016", // L2ToL1MessagePasser: Layer 2
            //     ['function initiateWithdrawal(address _target, uint256 _gasLimit, bytes memory _data) payable'],
            //     l2Signer
            //   );
            //   // initiateWithdrawal
            //   const target = address;
            //   const gasLimit = 300000; //
            //   const value = weiValue;
            //   const data = ethers.utils.hexlify('0x');

            //   setLoader(true);

            //   const withdrawalTx = await l2Contract.initiateWithdrawal(
            //     target,
            //     gasLimit,
            //     data,
            //     { value: value }
            //   );

            //   await withdrawalTx.wait();

            //   setLoader(false);
            //   setEthValue("");
            // }

            if (sendToken == "USDT") { // USDT = ERC-20
              var assetValue = Web3.utils.toWei(ethValue, "ether");
              setLoader(true);

              // Connect Layer 2
              const l2Provider = new ethers.providers.Web3Provider(window.ethereum);
              const l2Signer = l2Provider.getSigner();

              // Contract instance ERC-20 token on Layer 2
              const usdtContract = new ethers.Contract(
                process.env.REACT_APP___CHAIN5___L2_USDT, // ERC-20 Token contract address on Layer 1
                ['function approve(address spender, uint256 amount) returns (bool)'],
                l2Signer
              );

              // Token ERC-20 approve Layer 2 to contract on Layer 1
              const approvalTx = await usdtContract.approve(
                process.env.REACT_APP___CHAIN5___L2_BRIDGE, // L2 contract address for deposit
                assetValue
              );
              await approvalTx.wait();

              // Contract instance on Layer 1 (back to L1)
              if (approvalTx && approvalTx.value < assetValue) {
                // Contract instance on Layer 2
                const l2Contract = new ethers.Contract(
                  process.env.REACT_APP___CHAIN5___L2_BRIDGE, // Contract address on Layer 2
                  ['function bridgeERC20(address _localToken,address _remoteToken,uint256 _amount,uint32 _minGasLimit,bytes _extraData)'], // Call function
                  l2Signer
                );
                // withdraw
                const _localToken = process.env.REACT_APP___CHAIN5___L2_USDT; // amount
                const _remoteToken = process.env.REACT_APP___CHAIN5___L1_USDT;
                const _amount = assetValue; // amount
                const _minGasLimit = 100000; // gas limit (fix - 3000000 (90% +))
                const _extraData = ethers.utils.hexlify('0x'); // data (fix - 0=+)

                // Write bridgeERC20
                const depositTx = await l2Contract.bridgeERC20(_localToken, _remoteToken, _amount, _minGasLimit, _extraData);
                await depositTx.wait();
              }

              // Loader & ethValue 
              setLoader(false);
              setEthValue("");
            }


            //-------------------------------------------------------- SEND TOKEN VALUE END-----------------------------------------------------------------
          } catch (error) {
            setLoader(false);
            console.log({ error }, 98);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitch = () => {
    try {
      switchNetwork(process.env.REACT_APP___CHAIN5___L2_CHAIN_ID);
    } catch (error) {
      console.log(error);
    }
  };
  ////========================================================== HANDLE CHANGE =======================================================================

  const handleChange = (e) => {
    // if (sendToken == "ETH") {
    //   if (data?.formatted < e.target.value) {
    //     setErrorInput("Insufficient ETH balance.");
    //   } else {
    //     setErrorInput("");
    //   }
    //   setEthValue(e.target.value);
    // }
    if (sendToken == "USDT") {
      if (dataUSDT.data?.formatted < e.target.value) {
        setErrorInput("Insufficient USDT balance.");
      } else {
        setErrorInput("");
      }
      setEthValue(e.target.value);
    }
  };

  return (
    <>
      <div className="bridge_wrap">
        <TabMenuChain5 />
        <section className="deposit_wrap">
          <div className="withdraw_title_wrap">
            <div className="withdraw_title_icn">
              <MdOutlineSecurity />
            </div>
            <div className="withdraw_title_content">
              <h3>Important instructions for Withdrawals</h3>
              <p>Please follow these instruction carefully:</p>
              <br />
              <p>1. Enter amount bellow and confirm tx with Metamask</p>
              <p>
                2. Click to "View Withdraws" and find your tx (Up to 1 minutes
                later)
              </p>
              <p>3. Wait for ~15 minute challenge period</p>
              <p>4. Once confirmed, press the "Prove" button</p>
              <p>5. After ~5 minute, press the "Claim" button to complete tx</p>
              {/* <br /> */}
              {/* <p>
                Learn more about{" "}
                <a
                  href="https://docs.optimism.io/builders/app-developers/bridging/messaging"
                  target="_blank"
                  style={{ color: "white" }}
                >
                  OP Stack withdrawals
                </a>
              </p> */}
            </div>
          </div>
          <div className="deposit_price_wrap">
            <div className="deposit_price_title">
              <p>From</p>
              <h5>
                <img src={dustboy} alt="" /> dustboy IoT Blockchain
              </h5>
              <a href="https://dustboy-bridge.jibl2.com" target="_blank"
                className="native">
                Bridge for Native &nbsp;<FaExternalLinkAlt />
              </a>
            </div>
            <div className="deposit_input_wrap">
              <Form>
                <div className="deposit_inner_input">
                  <Form.Control
                    type="number"
                    name="eth_value"
                    value={ethValue}
                    onChange={handleChange}
                    placeholder="0"
                    min="0"
                    step="any"
                  />
                  <Form.Select
                    aria-label="Default select example"
                    className="select_wrap"
                    onChange={({ target }) => setSendToken(target.value)}
                  >

                    {/* <option>ETH</option> */}
                    <option>USDT</option>

                  </Form.Select>
                </div>
                <div className="input_icn_wrap">

                  {sendToken === "USDT" ? (
                    <span className="input_icn">
                      <Usdt style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : null}

                </div>
              </Form>
            </div>
            {errorInput && <small className="text-danger">{errorInput}</small>}

            {sendToken === "USDT" ? (
              address && (
                <p className="wallet_bal mt-2">
                  Balance: {Number(dataUSDT.data?.formatted).toFixed(5)} USDT
                </p>
              )
            ) : null}

          </div>
          <div className="deposit_details_wrap">
            <div className="deposit_details">
              <p>To:</p>
              <h5>
                <img src={jbc} alt="" /> JIB Chain L1
              </h5>
            </div>
            <div className="withdraw_bal_sum">

              {sendToken == "USDT" ? (
                <span className="input_icn">
                  <Usdt style={{ fontSize: "1.5rem" }} />
                </span>
              ) : null}

              <p> &nbsp;
                You’ll receive: {ethValue ? ethValue : "0"} {sendToken}
              </p>
            </div>
          </div>
          <div className="deposit_btn_wrap">
            {checkMetaMask === true ? (
              <a
                className="btn deposit_btn"
                href="https://metamask.io/"
                target="_blank"
              >
                <Image src={metamask} alt="metamask icn" fluid /> Please Install
                Metamask Wallet
              </a>
            ) : !isConnected ? (
              <button className="btn deposit_btn" onClick={() => connect()}>
                <IoMdWallet />
                Connect Wallet
              </button>
            ) : chain.id !== Number(process.env.REACT_APP___CHAIN5___L2_CHAIN_ID) ? (
              <button className="btn deposit_btn" onClick={handleSwitch}>
                <HiSwitchHorizontal />
                Switch to Network
              </button>
            ) : (
              <button
                className="btn deposit_btn"
                onClick={handleWithdraw}
                disabled={loader ? true : false}
              >
                {loader ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Withdraw"
                )}
              </button>
            )}
          </div>
          {metaMastError && (
            <small className="d-block text-danger text-center mt-2">
              {metaMastError}
            </small>
          )}
        </section>

        <br />
        <br />
        <ViewChain5 />

      </div>
    </>
  );
};

export default WithdrawChain5;
