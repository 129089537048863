import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import { motion } from "framer-motion";
import detectEthereumProvider from '@metamask/detect-provider';
import metamask from "../assets/images/metamask.svg";

function Home() {
  const [addedNetworks, setAddedNetworks] = useState({});
  const [showNotification, setShowNotification] = useState({});

  const list = [
    {
      name: "POM CHAIN",
      infoURL: "https://pom.jibl2.com",
      title: "$POM",
      color: "#f4a460",
      img: "https://pom.jibl2.com/assets/configs/network_icon.png",
      bridgeErc20: "/deposit/pom",
      bridgeNative: "https://pom-bridge.jibl2.com/",
      chainId: 7003,
      rpcUrls: ["https://pom-rpc.jibl2.com"],
      blockExplorerUrls: ["https://pom.jibl2.com"],
      nativeCurrency: {
        name: "POM",
        symbol: "POM",
        decimals: 18
      }
    },
    {
      name: "Sense IoT Blockchain",
      infoURL: "https://sense.jibl2.com",
      title: "$SENSE",
      color: "#006400",
      img: "https://sense.jibl2.com/assets/configs/network_icon.png",
      bridgeErc20: "/deposit/sen",
      bridgeNative: "https://sense-bridge.jibl2.com/",
      chainId: 7004,
      rpcUrls: ["https://sense-rpc.jibl2.com"],
      blockExplorerUrls: ["https://sense.jibl2.com"],
      nativeCurrency: {
        name: "POM",
        symbol: "POM",
        decimals: 18
      }
    },
    {
      name: "META Id Blockchain",
      infoURL: "https://meta-id.jibl2.com",
      title: "$META",
      color: "#1E90FF",
      img: "https://meta-id.jibl2.com/assets/configs/network_icon.png",
      bridgeErc20: "/deposit/meta",
      bridgeNative: "https://meta-id-bridge.jibl2.com/",
      chainId: 7005,
      rpcUrls: ["https://meta-id-rpc.jibl2.com"],
      blockExplorerUrls: ["https://meta-id.jibl2.com"],
      nativeCurrency: {
        name: "META",
        symbol: "META",
        decimals: 18
      }
    },
    {
      name: "Lavarock Gaming Blockchain",
      infoURL: "https://exp.lavarock.xyz",
      title: "$LVR",
      color: "#778899",
      img: "https://exp.lavarock.xyz/assets/configs/network_icon.png",
      bridgeErc20: "/deposit/lava",
      bridgeNative: "https://bridge.lavarock.xyz/",
      chainId: 8899001,
      rpcUrls: ["https://rpc.lavarock.xyz"],
      blockExplorerUrls: ["https://exp.lavarock.xyz"],
      nativeCurrency: {
        name: "LVR",
        symbol: "LVR",
        decimals: 18
      }
    },
    {
      name: "dustboy IoT Blockchain",
      infoURL: "https://dustboy.jibl2.com",
      title: "$DST",
      color: "#6495ED",
      img: "https://dustboy.jibl2.com/assets/configs/network_icon.png",
      bridgeErc20: "/deposit/dustboy",
      bridgeNative: "https://dustboy-bridge.jibl2.com",
      chainId: 555888,
      rpcUrls: ["https://dustboy-rpc.jibl2.com"],
      blockExplorerUrls: ["https://dustboy.jibl2.com"],
      nativeCurrency: {
        name: "DST",
        symbol: "DST",
        decimals: 18
      }
    },
  ];

  const addNetworkToMetaMask = async (network) => {
    const provider = await detectEthereumProvider();

    if (provider) {
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${network.chainId.toString(16)}`,
              chainName: network.name,
              rpcUrls: network.rpcUrls,
              blockExplorerUrls: network.blockExplorerUrls,
              nativeCurrency: network.nativeCurrency,
            },
          ],
        });
        setAddedNetworks((prev) => ({ ...prev, [network.chainId]: true }));
        setShowNotification((prev) => ({ ...prev, [network.chainId]: true }));
        setTimeout(() => {
          setShowNotification((prev) => ({ ...prev, [network.chainId]: false }));
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('MetaMask is not installed');
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, rotateY: -90 },
    visible: {
      opacity: 1,
      rotateY: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 20
      }
    }
  };

  return (
    <Container>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 1, scale: 0 },
          visible: {
            opacity: 1,
            scale: 1,
            transition: {
              delayChildren: 0.3,
              staggerChildren: 0.2
            }
          }
        }}
      >
        <Row className="justify-content-center" style={{ marginTop: '150px' }}>
          {list.map((item, index) => (
            <Col
              key={index}
              md="auto"
              className="mb-4 d-flex justify-content-center"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <motion.div
                variants={cardVariants}
              >
                <Card
                  data-resizable
                  sx={{
                    textAlign: 'center',
                    alignItems: 'center',
                    width: 343,
                    overflow: 'auto',
                    resize: 'horizontal',
                    '--icon-size': '100px',
                  }}
                >
                  <CardOverflow
                    sx={{
                      backgroundColor: item.color,
                    }}
                    color="warning"
                  >
                    <AspectRatio
                      ratio="1"
                      sx={{
                        m: 'auto',
                        transform: 'translateY(50%)',
                        borderRadius: '50%',
                        width: 'var(--icon-size)',
                        boxShadow: 'sm',
                        bgcolor: 'background.surface',
                        position: 'relative',
                      }}
                    >
                      <img src={item.img} alt={item.name} style={{ borderRadius: '50%' }} />
                    </AspectRatio>
                  </CardOverflow>
                  <Typography level="title-lg" sx={{ mt: 'calc(var(--icon-size) / 2)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <a href={item.infoURL} target="_blank" rel="noopener noreferrer" title='Explorer' style={{ color: 'inherit', textDecoration: 'none' }}>
                      {item.name}
                    </a>
                  </Typography>
                  <CardContent sx={{ maxWidth: '40ch' }}>
                    {item.title}
                  </CardContent>
                  <img
                    src={metamask}
                    alt="MetaMask"
                    title='Add Network'
                    onClick={() => addNetworkToMetaMask(item)}
                    style={{ cursor: 'pointer', marginLeft: '8px', width: '24px', height: '24px' }}
                  />
                  {showNotification[item.chainId] && (
                    <Typography level="body2" color="success" sx={{ mt: 1 }}>
                      Network added!
                    </Typography>
                  )}
                  <CardActions
                    orientation="vertical"
                    buttonFlex={1}
                    sx={{
                      '--Button-radius': '40px',
                      width: 'clamp(min(100%, 160px), 50%, min(100%, 200px))',
                    }}
                  >
                    <Link to={item.bridgeErc20}>
                      <Button sx={{ bgcolor: 'black' }} color="neutral">
                        Bridge for ERC20
                      </Button>
                    </Link>

                    <a href={item.bridgeNative} target='_blank'>
                      <Button sx={{ bgcolor: 'white', color: 'blue' }} color="neutral">
                        Bridge for Native ↗︎
                      </Button>
                    </a>
                  </CardActions>
                </Card>
              </motion.div>
            </Col>
          ))}
        </Row>
      </motion.div>
    </Container>
  );
}

export default Home;
