import React from "react";
import { Link } from "react-router-dom";
import "../../assets/style/common/footer.scss";
import { BsDiscord } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import logo from "../../assets/images/jbc.png";
import jbcbridge from "../../assets/images/jbcbridge.png";
import jbclist from "../../assets/images/jbclist.png";
import { FaLink, FaTwitter, FaFacebook, FaTelegram } from "react-icons/fa";
import { Image } from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <footer className="app_footer">
        {/* <Container fluid> */}
        <div className="footer_text_wrap">
          <ul>
            <li>
              <Link to="https://jibchain.net/" target="_blank">
                <Image src={logo} alt="logo" width="80" fluid />
              </Link>
            </li>
            <li>
              <Link to="https://bridge.jbcha.in/" target="_blank">
                <img src={jbcbridge} target="_blank"
                alt="logo" width="100" fluid
                ></img>
              </Link>
            </li>
            <li>
              <Link to="https://list.jibl2.com/" target="_blank">
                <img src={jbclist} target="_blank"
                alt="logo" width="100" fluid
                ></img>
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer_icn_wrap">
          <ul>
            <li>
              <Link to="https://linktr.ee/jibchain_official" target="_blank">
                <FaLink />
              </Link>
            </li>
            <li>
              <Link to="https://x.com/jibchain" target="_blank">
                <FaTwitter />
              </Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/jibchainofficial" target="_blank">
                <FaFacebook />
              </Link>
            </li>
            <li>
              <Link to="https://t.me/jibchain" target="_blank">
                <FaTelegram />
              </Link>
            </li>

          </ul>
        </div>
        {/* </Container> */}
      </footer>
    </>
  );
};

export default Footer;
