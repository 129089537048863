import React from "react";
import { Link, useLocation } from "react-router-dom";

const TabMenuChain5 = () => {
  const location = useLocation();

  return (
    <>
      <ul>
        <li>
          <Link
            to="/deposit/dustboy"
            className={`${location.pathname == "/" ? "active" : location.pathname == "/deposit/dustboy" ? "active" : ""}`}
          >
            Deposit
          </Link>
        </li>
        <li>
          <Link
            to="/withdraw/dustboy"
            className={`${location.pathname == "/withdraw/dustboy" ? "active" : ""}`}
          >
            Withdraw
          </Link>
        </li>
      </ul>
    </>
  );
};

export default TabMenuChain5;
