import React, { useEffect, useState } from "react";
import "../../assets/style/common/header.scss";
import {
  Navbar,
  Container,
  Nav,
  Image,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import logo from "../../assets/images/jbc-logo.png";
import { Link } from "react-router-dom";
import { useAccount, useConnect, useNetwork } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { disconnect } from "@wagmi/core";
import { FaEthereum } from "react-icons/fa";
import { BiInfoCircle, BiPowerOff } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";
import { AiOutlineDownload, AiOutlineUpload } from "react-icons/ai";
import metamask from "../../assets/images/metamask.svg";
// import { BiPowerOff } from "react-icons/bi"
import { useDisconnect } from "wagmi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import pom from "../../assets/images/pom.png";
import meta from "../../assets/images/meta.png";
import sen from "../../assets/images/sen.png";
import lava from "../../assets/images/lavarock.png";
import dustboy from "../../assets/images/dustboy.png";

const HeaderNew = () => {
  const [copyTextSourceCode, setCopyTextSourceCode] = useState(
    "Copy address to clipboard",
  );
  const { address, isConnected } = useAccount();
  const [getNetwork, setNetwork] = useState();
  const [checkMetaMask, setCheckMetaMask] = useState("");
  const { chain, chains } = useNetwork();
  const { connect } = useConnect({
    connector: new InjectedConnector({ chains }),
    onMutate(args) {
      console.log("Mutate", args);
      if (args.connector.ready === true) {
        setCheckMetaMask(false);
      } else {
        setCheckMetaMask(true);
      }
    },
    onSettled(data, error) {
      console.log("Settled", { data, error });
    },
    onSuccess(data) {
      console.log("Success", data);
    },
  });
  const handleDisconnect = async () => {
    await disconnect();
  };
  useEffect(() => {
    if (chain?.id == 8899) {
      setNetwork("JIBCHAIN L1");
    } else if (chain?.id == 7003) {
      setNetwork("POM CHAIN");
    } else if (chain?.id == 7004) {
      setNetwork("Sense IoT Blockchain");
    } else if (chain?.id == 7005) {
      setNetwork("META Id Blockchain");
    } else if (chain?.id == 8899001) {
      setNetwork("Lavarock Gaming Blockchain");
    } else if (chain?.id == 555888) {
      setNetwork("dustboy IoT Blockchain");
    } else {
      setNetwork("Unsupported Network");
    }
  }, [chain]);
  const handleSourceCopy = () => {
    if (copyTextSourceCode === "Copy address to clipboard") {
      setCopyTextSourceCode("Copied.");
    }
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {copyTextSourceCode}
    </Tooltip>
  );

  return (
    <>
      <header className="app_header">
        <Navbar expand="lg" variant="dark">
          <Container fluid>

            <Link to="/" className="app_logo" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <Image
                src={logo}
                alt="logo"
                fluid
                style={{ maxHeight: '20%', maxWidth: '20%', textDecoration: 'none' }}
              />
              <b style={{ color: 'white', marginLeft: '10px', textDecoration: 'none' }}>
                JBC Stack L2
              </b>
            </Link>

            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              <div className="right_header_wrap">
                <div className="header_btn_wrap">
                  {isConnected ? (
                    <button className="btn disconnect_btn header_btn me-2">
                      {getNetwork}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="dropdown_wrap">
                  {checkMetaMask === true ? (
                    <a
                      className="btn disconnect_btn header_btn"
                      href="https://metamask.io/"
                      target="_blank"
                    >
                      <Image src={metamask} alt="metamask icn" fluid /> Please
                      Install Metamask Wallet
                    </a>
                  ) : address ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="race_header_dropdown"
                      >
                        {address.slice(0, 5)}...{address.slice(-5)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="user_profile_wrap">
                          &nbsp;
                          <figure className="user_profile">
                            <Image
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAsElEQVRYR2PslBX+z4AHHLiVgiLroDYHn3IGUtUzjjpg0IUAehyiRzipaYCQfow0MOoAuoeA5/dylHIAPY7RHWSt9Q5vOXD0mhDecgPdPMZRBwx4CJBaEOFNAFgkCZUbJNcFow4YDYHREBjwEKC0LkD3AMnlwKgDqB4CLYqpKO0BQvX5b5YgvOmQ9c86FHlC7QnGUQcMeAigN0jQIxg90aGnEUrVY7QJKTWQVAePOgAAXAoAZIiZ6M4AAAAASUVORK5CYII="
                              alt="Profile Icon"
                            />
                          </figure>
                          &nbsp;
                          <h4>
                            {address.slice(0, 5)}...{address.slice(-5)} &nbsp;
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 250 }}
                              overlay={renderTooltip}
                            >
                              <CopyToClipboard text={address} >
                                <span className="d-inline-block">
                                  <MdContentCopy onClick={handleSourceCopy} /> {" "}
                                </span>
                              </CopyToClipboard>
                            </OverlayTrigger>
                          </h4>
                        </div>

                        <Dropdown.Item as={Link} to="/deposit/pom">
                          <img src={pom} alt="" style={{ maxHeight: '10%', maxWidth: '10%', borderRadius: '50%', objectFit: 'cover' }} /> &nbsp; POM CHAIN
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/deposit/sen">
                          <img src={sen} alt="" style={{ maxHeight: '10%', maxWidth: '10%', borderRadius: '50%', objectFit: 'cover' }} /> &nbsp; Sense IoT Blockchain
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/deposit/meta">
                          <img src={meta} alt="" style={{ maxHeight: '10%', maxWidth: '10%' }} /> &nbsp; META Id Blockchain
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/deposit/lava">
                          <img src={lava} alt="" style={{ maxHeight: '10%', maxWidth: '10%' }} /> &nbsp; Lavarock Gaming Blockchain
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/deposit/dustboy">
                          <img src={dustboy} alt="" style={{ maxHeight: '10%', maxWidth: '10%' }} /> &nbsp; dustboy IoT Blockchain
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => handleDisconnect()}>
                          <BiPowerOff /> Disconnect
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <button
                      onClick={() => connect()}
                      className="btn disconnect_btn header_btn"
                    >
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};
export default HeaderNew;
