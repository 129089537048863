import React, { useState, useEffect } from "react";
import "../assets/style/deposit.scss";
import { Form, Spinner, Image, Dropdown } from "react-bootstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Usdt, Usdc, Ethereum, Dai } from "react-web3-icons";
import meta from "../assets/images/meta.png";
import { IoMdWallet } from "react-icons/io";
import jbc from "../assets/images/jbc-logo.png";
import {
  useAccount,
  useConnect,
  useNetwork,
  useSwitchNetwork,
  useBalance,
  useToken,
  useContractRead,
} from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { HiSwitchHorizontal } from "react-icons/hi";
import metamask from "../assets/images/metamask.svg";
import Web3 from "web3";
import TabMenuChain3 from "./TabMenuChain3";
import ViewChain3 from "./ViewChain3";
const optimismSDK = require("@eth-optimism/sdk");
const ethers = require("ethers");

const DepositChain3 = () => {
  const [ethValue, setEthValue] = useState("");
  const [sendToken, setSendToken] = useState("USDT");
  const { data: accountData, address, isConnected } = useAccount();
  const [errorInput, setErrorInput] = useState("");
  const [loader, setLoader] = useState(false);
  const { chain, chains } = useNetwork();
  const [checkMetaMask, setCheckMetaMask] = useState("");

  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect({
      connector: new InjectedConnector({ chains }),
      onError(error) {
        console.log("Error", error);
      },
      onMutate(args) {
        console.log("Mutate", args);
        if (args.connector.ready === true) {
          setCheckMetaMask(false);
        } else {
          setCheckMetaMask(true);
        }
      },
      onSettled(data, error) {
        console.log("Settled", { data, error });
      },
      onSuccess(data) {
        console.log("Success", data);
      },
    });
  const { switchNetwork } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
    onError(error) {
      console.log("Error", error);
    },
    onMutate(args) {
      console.log("Mutate", args);
    },
    onSettled(data, error) {
      console.log("Settled", { data, error });
    },
    onSuccess(data) {
      console.log("Success", data);
    },
  });

  const { data } = useBalance({
    address: address,
    watch: true,
    chainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
  });

  const dataUSDT = useBalance({
    address: address,
    token: process.env.REACT_APP___CHAIN3___L1_USDT,
    watch: true,
    chainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
  });


  const handleSwitch = () => {
    switchNetwork(process.env.REACT_APP_L1_CHAIN_ID);
  };

  const handleDeposit = async () => {
    try {
      if (!ethValue) {
        setErrorInput("Please enter the amount");
      } else {
        if (!parseFloat(ethValue) > 0) {
          setErrorInput("Invalid Amount Entered!");
        } else {
          const l2Url = process.env.REACT_APP_L1_RPC_URL;
          const l1Provider = new ethers.providers.Web3Provider(window.ethereum);
          const l2Provider = new ethers.providers.JsonRpcProvider(l2Url, "any");
          const l1Signer = l1Provider.getSigner(address);
          const l2Signer = l2Provider.getSigner(address);
          const zeroAddr = "0x".padEnd(42, "0");
          const l1Contracts = {
            StateCommitmentChain: zeroAddr,
            CanonicalTransactionChain: zeroAddr,
            BondManager: zeroAddr,
            AddressManager: process.env.REACT_APP___CHAIN3___LIB_ADDRESSMANAGER,
            L1CrossDomainMessenger:
              process.env.REACT_APP___CHAIN3___PROXY_OVM_L1CROSSDOMAINMESSENGER,
            L1StandardBridge: process.env.REACT_APP___CHAIN3___PROXY_OVM_L1STANDARDBRIDGE,
            OptimismPortal: process.env.REACT_APP___CHAIN3___OPTIMISM_PORTAL_PROXY,
            L2OutputOracle: process.env.REACT_APP___CHAIN3___L2_OUTPUTORACLE_PROXY,
          };
          const bridges = {
            Standard: {
              l1Bridge: process.env.REACT_APP___CHAIN3___PROXY_OVM_L1STANDARDBRIDGE, // 
              l2Bridge: process.env.REACT_APP___CHAIN3___L2_BRIDGE,
              Adapter: optimismSDK.StandardBridgeAdapter,
            },
            ETH: {
              l1Bridge: l1Contracts.L1StandardBridge,
              l2Bridge: process.env.REACT_APP___CHAIN3___L2_BRIDGE,
              Adapter: optimismSDK.ETHBridgeAdapter,
            },
          };
          const crossChainMessenger = new optimismSDK.CrossChainMessenger({
            contracts: {
              l1: l1Contracts,
            },
            bridges: bridges,
            l1ChainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
            l2ChainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
            l1SignerOrProvider: l1Signer,
            l2SignerOrProvider: l2Signer,
            bedrock: true,
          });
          // if (sendToken === "ETH") {
          //   console.log(sendToken);
          //   const weiValue = parseInt(
          //     ethers.utils.parseEther(ethValue)._hex,
          //     16,
          //   );
          //   setLoader(true);
          //   var depositETHEREUM = await crossChainMessenger.depositETH(
          //     weiValue.toString(),
          //   );
          //   const receiptETH = await depositETHEREUM.wait();
          //   if (receiptETH) {
          //     setLoader(false);
          //     setEthValue("");
          //   }
          // }

          // if (sendToken === "ERC-20") { 
          //   var assetValue = Web3.utils.toWei(ethValue, "ether");
          //   setLoader(true);

          //   // Connect Layer 1
          //   const l1Provider = new ethers.providers.Web3Provider(window.ethereum);
          //   const l1Signer = l1Provider.getSigner();

          //   // Contract instance ERC-20 token on Layer 1
          //   const usdtContract = new ethers.Contract(
          //     process.env.REACT_APP_L1_USDT, // ERC-20 Token contract address on Layer 1
          //     ['function approve(address spender, uint256 amount) returns (bool)'],
          //     l1Signer
          //   );

          //   // Token ERC-20 approve Layer 1 to contract on Layer 2
          //   const approvalTx = await usdtContract.approve(
          //     process.env.REACT_APP_OPTIMISM_PORTAL_PROXY, // L2 contract address for deposit
          //     assetValue
          //   );
          //   await approvalTx.wait();

          //   // Check if approval is less than assetValue
          //   if (approvalTx && approvalTx.value < assetValue) {
          //     // Contract instance on Layer 2
          //     const l2Contract = new ethers.Contract(
          //       process.env.REACT_APP_OPTIMISM_PORTAL_PROXY, // Contract address on Layer 2
          //       ['function depositERC20Transaction(address _to, uint256 _mint, uint256 _value, uint64 _gasLimit, bool _isCreation, bytes memory _data)'], // Call to depositERC20Transaction
          //       l1Signer
          //     );
          //     // depositERC20Transaction
          //     const to = address; // address
          //     const mint = assetValue; // amount
          //     const value = assetValue; // amount
          //     const gasLimit = 3000000; // gas limit (fix - 3000000 (90% +))
          //     const isCreation = false; // fix - false
          //     const data = ethers.utils.hexlify('0x'); // data (fix - 0=+)

          //     // Write depositERC20Transaction
          //     const depositTx = await l2Contract.depositERC20Transaction(to, mint, value, gasLimit, isCreation, data);
          //     await depositTx.wait();
          //   }

          //   // Loader & ethValue 
          //   setLoader(false);
          //   setEthValue("");
          // }

          if (sendToken === "USDT") {
            var assetValue = Web3.utils.toWei(ethValue, "ether");
            setLoader(true);

            // Connect Layer 1
            const l1Provider = new ethers.providers.Web3Provider(window.ethereum);
            const l1Signer = l1Provider.getSigner();

            // Contract instance ERC-20 token on Layer 1
            const usdtContract = new ethers.Contract(
              process.env.REACT_APP___CHAIN3___L1_USDT, // ERC-20 Token contract address on Layer 1
              ['function approve(address spender, uint256 amount) returns (bool)'],
              l1Signer
            );

            // Token ERC-20 approve Layer 1 to contract on Layer 2
            const approvalTx = await usdtContract.approve(
              process.env.REACT_APP___CHAIN3___PROXY_OVM_L1STANDARDBRIDGE, // L1 contract address for deposit
              assetValue
            );
            await approvalTx.wait();

            // Contract instance on Layer 2
            const l1Contract = new ethers.Contract(
              process.env.REACT_APP___CHAIN3___PROXY_OVM_L1STANDARDBRIDGE, // Contract address on Layer 1
              ['function depositERC20(address _l1Token, address _l2Token, uint256 _amount, uint32 _minGasLimit, bytes _extraData)'], // Call function
              l1Signer
            );

            // Parameters for depositERC20
            const _l1Token = process.env.REACT_APP___CHAIN3___L1_USDT; // token l1 amount
            const _l2Token = process.env.REACT_APP___CHAIN3___L2_USDT; // token l2 amount
            const _amount = assetValue; // amount
            const _minGasLimit = 100000; // gas limit (fix - 100000)
            const _extraData = '0x'; // data (fix - 0x)

            // Write depositERC20Transaction
            const depositTx = await l1Contract.depositERC20(_l1Token, _l2Token, _amount, _minGasLimit, _extraData);
            await depositTx.wait();

            // Loader & ethValue 
            setLoader(false);
            setEthValue("");
          }

        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    // if (sendToken == "ETH") {
    //   if (data?.formatted < e.target.value) {
    //     setErrorInput("Insufficient ETH balance.");
    //   } else {
    //     setErrorInput("");
    //   }
    //   setEthValue(e.target.value);
    // }
    if (sendToken == "USDT") {
      if (dataUSDT.data?.formatted < e.target.value) {
        setErrorInput("Insufficient USDT balance.");
      } else {
        setErrorInput("");
      }
      setEthValue(e.target.value);
    }
  };

  return (
    <>
      <div className="bridge_wrap">
        <TabMenuChain3 />
        <section className="deposit_wrap">
          <div className="deposit_price_wrap">
            <div className="deposit_price_title">
              <p>From</p>
              <h5>
                <img src={jbc} alt="" /> JIB Chain L1
              </h5>
              <Dropdown drop="end" style={{ marginLeft: 'auto' }}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Gas for $JBC
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="https://www.coinstore.com/spot/JBCUSDT" target="_blank">
                    Coinstore <FaExternalLinkAlt />
                  </Dropdown.Item>
                  <Dropdown.Item href="https://bridge.jbcha.in/" target="_blank">
                    JBCBridge <FaExternalLinkAlt />
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item disabled>
                    Other
                  </Dropdown.Item>
                  <Dropdown.Item href="https://commudao.xyz/tbridge" target="_blank">
                    CommuDAO <FaExternalLinkAlt />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="deposit_input_wrap">
              <Form>
                <div className="deposit_inner_input">
                  <Form.Control
                    type="number"
                    value={ethValue}
                    onChange={handleChange}
                    placeholder="0"
                    min="0"
                    step="any"
                  />
                  <Form.Select
                    aria-label="Default select example"
                    className="select_wrap"
                    onChange={({ target }) => setSendToken(target.value)}
                  >
                    {/* <option>ETH</option> */}
                    <option>USDT</option>

                  </Form.Select>
                </div>
                <div className="input_icn_wrap">

                  {sendToken == "USDT" ? (
                    <span className="input_icn">
                      <Usdt style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : null}

                </div>
              </Form>
            </div>
            {errorInput && <small className="text-danger">{errorInput}</small>}

            {sendToken == "USDT" ? (
              address && (
                <p className="wallet_bal mt-2">
                  Balance: {Number(dataUSDT.data?.formatted).toFixed(5)} USDT
                </p>
              )
            ) : null}

          </div>
          <div className="deposit_details_wrap">
            <div className="deposit_details">
              <p>To</p>
              <h5>
                <img src={meta} alt="" /> META Id Blockchain
              </h5>
            </div>
            <div className="deposit_inner_details">

              {sendToken == "USDT" ? (
                <span className="input_icn">
                  <Usdt style={{ fontSize: "1.5rem" }} />
                </span>
              ) : null}


              <p>
                {" "}
                You’ll receive: {ethValue ? ethValue : "0"} {sendToken}
              </p>
            </div>
          </div>
          <div className="deposit_btn_wrap">
            {checkMetaMask === true ? (
              <a
                className="btn deposit_btn"
                href="https://metamask.io/"
                target="_blank"
              >
                <Image src={metamask} alt="metamask icn" fluid /> Please Install
                Metamask Wallet
              </a>
            ) : !isConnected ? (
              <button className="btn deposit_btn" onClick={() => connect()}>
                <IoMdWallet />
                Connect Wallet
              </button>
            ) : chain.id !== Number(process.env.REACT_APP_L1_CHAIN_ID) ? (
              <button className="btn deposit_btn" onClick={handleSwitch}>
                <HiSwitchHorizontal />
                Switch to Network
              </button>
            ) : (
              <button
                className="btn deposit_btn"
                onClick={handleDeposit}
                disabled={loader ? true : false}
              >
                {" "}
                {loader ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Deposit"
                )}{" "}
              </button>
            )}
          </div>
        </section>

        <br />
        <br />
        <ViewChain3 />

      </div>
    </>
  );
};

export default DepositChain3;
